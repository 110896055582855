"use strict";

document.addEventListener('DOMContentLoaded', function () {

    document.querySelector('.fixed__wrap h3').addEventListener('click', (event) => {
        event.preventDefault();
        window.location = '/'
    })

    // =============== modal ================
    let modalWrap = document.querySelectorAll('.modal__wrap');

    document.querySelectorAll('.modal__show').forEach(function (el) {
        el.onclick = showModal;
    })
    modalWrap.forEach(function (el) {
        el.onclick = closeModal;
    })
    document.querySelectorAll('.modal__close').forEach(function (el) {
        el.onclick = closeModal;
    })
    document.querySelectorAll('.modal').forEach((el) => {
        el.onclick = (event) => {
            event.stopPropagation();
        }
    })

    function showModal(event) {
        event.preventDefault();
        let data = this.dataset.modal;
        document.querySelectorAll(data).forEach((el) => {
            el.classList.remove('hide');
        })
        document.onkeydown = (el) => {
            if (el.keyCode == 27) {
                closeModal();
            }
        }
    }
    function closeModal() {
        for (let i = 0; i < modalWrap.length; i++) {
            modalWrap[i].classList.add('hide');
        }
        document.onkeydown = null;
    }

    // =============== mail send ================

    const form = document.getElementById('form');
    form.addEventListener('submit', formSend);

    function formSend(e) {
        e.preventDefault();

        let formData = new FormData(form);
        let response = fetch('mail.php', {
            method: 'POST',
            body: formData,
        });
        document.querySelector('.modal').classList.add('sending');
        response.then(data => {

            if (data.ok) {
                setTimeout(function () {
                    document.querySelector('.modal').classList.remove('sending');
                    document.querySelector('.modal').innerHTML = '<h3>Спасибо за сообщение!<br>Мы скоро свяжемся с Вами</h3>'
                    form.reset();
                    setTimeout(function () {
                        document.querySelector('.modal__wrap').classList.add('hide');
                    }, 1000)
                }, 1000);

            } else {
                alert('Возникла ошибка при отправке. Попробуйте еще раз.')
            }
        })
    }

    document.body.addEventListener('click', event => {
        if(event.target.classList.contains('demo_item')){
            showModal2();
        }
    })

    function showModal2(){
        document.querySelector('.modal__wrap').classList.remove('hide');
        let img = event.target.getElementsByTagName('img')[0].id;
        document.querySelector('.modal-2').innerHTML = `<img src="./img/demo/site-${img}.jpg" alt="">`
    }
})

let demoImg = [
    {src: './img/slider/site-1.jpg'},
    {src: './img/slider/site-2.jpg'},
    {src: './img/slider/site-3.jpg'},
    {src: './img/slider/site-4.jpg'},
    {src: './img/slider/site-5.jpg'},
    {src: './img/slider/site-6.jpg'},
    {src: './img/slider/site-7.jpg'},
    {src: './img/slider/site-8.jpg'},
    {src: './img/slider/site-9.jpg'},
    {src: './img/slider/site-10.jpg'},
    {src: './img/slider/site-11.jpg'},
    {src: './img/slider/site-12.jpg'},
    {src: './img/slider/site-13.jpg'},
    {src: './img/slider/site-14.jpg'},
    {src: './img/slider/site-15.jpg'},
    {src: './img/slider/site-16.jpg'},
    {src: './img/slider/site-17.jpg'},
    {src: './img/slider/site-18.jpg'},
    {src: './img/slider/site-19.jpg'},
    {src: './img/slider/site-20.jpg'},
]
let out = '';
demoImg.forEach((el, i) => {
    out += `
        <div class="demo_item">
            <img src="${el.src}" alt="" id="${i + 1}">
        </div>
    `
})
let demoList = document.querySelector('.demo_list');
if(demoList){
    demoList.innerHTML = out;
}
